import $ from "jquery";
window.$ = $;
window.jQuery = $;

import Popper from "popper.js";
import bootstrap from "bootstrap";

var translations = {
  'index': {
    'en-US': {
      'title': 'Index',
      'header': 'Index',
      'description': 'OK',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'en-GB': {
      'title': 'Index',
      'header': 'Index',
      'description': 'OK',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'ru-RU': {
      'title': 'Index',
      'header': 'Index',
      'description': 'ОК',
      'home': 'Главная страница',
      'reload': 'Обновить',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Навигация',
    }
  },
  '403': {
    'en-US': {
      'title': '403 Forbidden',
      'header': '403 Forbidden',
      'description': 'Access denied to this page.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'en-GB': {
      'title': '403 Forbidden',
      'header': '403 Forbidden',
      'description': 'Access denied to this page.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'ru-RU': {
      'title': '403 Доступ запрещен',
      'header': '403 Доступ запрещен',
      'description': 'Доступ к запрошенной странице запрещен.',
      'home': 'Главная страница',
      'reload': 'Обновить',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Навигация',
    }
  },
  '404': {
    'en-US': {
      'title': '404 Not Found',
      'header': '404 Not Found',
      'description': 'Page not found.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'en-GB': {
      'title': '404 Not Found',
      'header': '404 Not Found',
      'description': 'Page not found.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'ru-RU': {
      'title': '404 Страница не найдена',
      'header': '404 Страница не найдена',
      'description': 'Запрошенная страница не найдена.',
      'home': 'Главная страница',
      'reload': 'Обновить',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Навигация',
    }
  },
  '500': {
    'en-US': {
      'title': '500 Internal server error',
      'header': '500 Internal server error',
      'description': 'There is an error in server software. We will solve this issue soon.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'en-GB': {
      'title': '500 Internal server error',
      'header': '500 Internal server error',
      'description': 'There is an error in server software. We will solve this issue soon.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'ru-RU': {
      'title': '500 Внутренняя ошибка сервера',
      'header': '500 Внутренняя ошибка сервера',
      'description': 'В программном обеспечении сервера произошла ошибка. В ближайшее время проблема будет решена.',
      'home': 'Главная страница',
      'reload': 'Обновить',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Навигация',
    }
  },
  '502': {
    'en-US': {
      'title': '502 Bad Gateway',
      'header': '502 Bad Gateway',
      'description': 'One of the servers in a chain does not respond. We will solve this issue soon.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'en-GB': {
      'title': '502 Bad Gateway',
      'header': '502 Bad Gateway',
      'description': 'One of the servers in a chain does not respond. We will solve this issue soon.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'ru-RU': {
      'title': '502 Плохой шлюз',
      'header': '502 Плохой шлюз',
      'description': 'Один из серверов цепочки не отвечает. В ближайшее время проблема будет решена.',
      'home': 'Главная страница',
      'reload': 'Обновить',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Навигация',
    }
  },
  '503': {
    'en-US': {
      'title': '503 Service Unavailable',
      'header': '503 Service Unavailable',
      'description': 'Server software could not respond to your request. We will solve this issue soon.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'en-GB': {
      'title': '503 Service Unavailable',
      'header': '503 Service Unavailable',
      'description': 'Server software could not respond to your request. We will solve this issue soon.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'ru-RU': {
      'title': '503 Cервис недоступен',
      'header': '503 Cервис недоступен',
      'description': 'Программное обеспечение сервера не может обработать ваш запрос. В ближайшее время проблема будет решена.',
      'home': 'Главная страница',
      'reload': 'Обновить',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Навигация',
    }
  },
  '504': {
    'en-US': {
      'title': '504 Gateway Timeout',
      'header': '504 Gateway Timeout',
      'description': 'One of the servers in a chain does not respond. We will solve this issue soon.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'en-GB': {
      'title': '504 Gateway Timeout',
      'header': '504 Gateway Timeout',
      'description': 'One of the servers in a chain does not respond. We will solve this issue soon.',
      'home': 'Main page',
      'reload': 'Reload',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Navigation',
    },
    'ru-RU': {
      'title': '504 Шлюз не отвечает',
      'header': '504 Шлюз не отвечает',
      'description': 'Один из серверов цепочки не отвечает. В ближайшее время проблема будет решена.',
      'home': 'Главная страница',
      'reload': 'Обновить',
      'email': 'artem@aleksashkin.com',
      'navigation': 'Навигация',
    }
  },
};
$(function () {
  var page = $('main').data('page');
  if (page in translations) {
      var language = navigator.language || navigator.userLanguage;
      if (!(language in translations[page])) {
          language = 'en-US';
      }
      document.title = translations[page][language]['title'];
      $('.label-header').html(translations[page][language]['header']);
      $('.label-description').html(translations[page][language]['description']);
      $('.label-home').html(translations[page][language]['home']);
      $('.label-reload').html(translations[page][language]['reload']);
      $('.label-navigation').html(translations[page][language]['navigation']);
      $('.label-email').html(translations[page][language]['email']);
      $('.label-link-email').prop('href', 'mailto:' + translations[page][language]['email']);
  }
});
